import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Typography, Input } from "@material-tailwind/react";

import General from '../../layouts/General';
import recommengineFeatureDescriptions from '../recommengineFeatureDescriptions';

import { collection, doc, updateDoc, addDoc } from "firebase/firestore";
import { db, auth } from '../../FirebaseConfig'



async function registerAgent(agentName, agentDescription, agentRole, agentFeatures) {
    try {
        let userId = auth.currentUser.uid
        const docRef = await addDoc(collection(db, "recommengine/users/"+ userId +"/data/agents"), {
            name: agentName,
            description: agentDescription,
            role: agentRole,
            features: agentFeatures,
            userId: userId,
            createdAt: new Date().toISOString(),
        }).catch((error) => {
            console.error("Error adding document: ", error);
            return false
        })

        return docRef
    } catch (e) {
        console.error("Error adding document: ", e);
        return false
    }
}

async function saveAgent(agentName, agentDescription, agentRole, agentFeatures) {
    console.log('save agent');
    return registerAgent(agentName, agentDescription, agentRole, agentFeatures)
        .then((result) => {
            console.log(result);
            return result;
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
            return false;
        });
}

export function AgentGenerator() {
    const [currentStep, setCurrentStep] = useState(1)
    const [step, setStep] = useState(1)
    const [agentName, setAgentName] = useState("")
    const [agentDescription, setAgentDescription] = useState("")
    const [agentRole, setAgentRole] = useState("")
    const [agentFeatures, setAgentFeatures] = useState({
        "map": true,
        "chat": true,
        "camera": true,
        "calendar": true,
        // "pushNotifications": false,
        // "onboardingQuestions": false,
        // "userProfile": false,
        // "social": false,
    })
    const [agentFeaturesDescription, setAgentFeaturesDescription] = useState([])
    const [errors, setErrors] = useState([])  
    const [agentId, setAgentId] = useState(null)
    const validateForm = () => {
        if (agentName && agentRole) {
            return true
        } else {
            setErrors(["Agent Name and Role are required"])
            return false
        }
    }

    const updateStep = (newStep) => {
        setStep(newStep);
        setCurrentStep(newStep);
    };

    const proceed = (step) => {
        console.log('proceed from current step ' + currentStep + ' to step ' + step)
        switch(currentStep) {
            case 1:
                if (validateForm()) {
                    setErrors([])
                    setStep(step)
                    setCurrentStep(step)
                }
                break
            // case 2:
            //     if (validateForm()) {
            //         setErrors([])
            //         setStep(step)
            //         setCurrentStep(step)
            //     }
            //     break;
            case 2:
                if (validateForm()) {
                    console.log('validated')
                    setErrors([])

                    new Promise(function(resolve) {
                        resolve(saveAgent(agentName, agentDescription, agentRole, agentFeatures))
                    }).then((result) => {
                        console.log('received result from step 2: ', result)
                        if (result && result._key && result._key.path) {
                            const id = result._key.path.segments[result._key.path.segments.length - 1]
                            console.log('agent saved: ', id)
                            setAgentId(id)
                            setStep(3)
                            setCurrentStep(3)
                        }
                    }).catch((error) => {
                        console.error("Error adding document: ", error);
                    })
                }
                break;
            case 4:
                if (validateForm()) {
                    console.log('validated')
                    setErrors([])
                }
                break;
            default:
                setStep(step)
                setCurrentStep(step)
        }
    }
    
    // const goDashboard = () => {
    //     // redirect to /dashboard
    //     window.location.href = '/dashboard'
    // }

    const goAgent = () => {
        // redirect to /agents/:id
        window.location.href = '/agents/' + agentId
    }

    const storeFeature = (event, feature) => {
        setAgentFeatures(prev => ({
            ...prev,
            [feature]: event.target.checked
        }));

        // Handle feature description updates
        const featureDescriptions = recommengineFeatureDescriptions;
        try {
            const featureDescription = featureDescriptions.find(f => f.name === feature).description;
            if (event.target.checked) {
                setAgentFeaturesDescription(prev => [...prev, featureDescription]);
            } else {
                setAgentFeaturesDescription(prev => prev.filter(desc => desc !== featureDescription));
            }
        } catch (error) {
            console.log(error);
        }   
    }

    return (
        <General title="Create New Agent">
            <div className='flex flex-col w-full h-full p-2 md:p-2'>
                {/* <Header /> */}

                <div className='flex flex-col md:flex-row w-full'>
                    {/* <div>
                        <Typography variant='h4' className='ml-4'>
                            Create New Agent
                        </Typography>

                        <NavigationLinks />
                    </div> */}

                {step === 1 && (
                    <div className='flex flex-col mt-6 md:mt-2'>
                        <div className=''>
                            <Typography variant='h3'>Name your agent</Typography>
                        </div>

                        <hr/>

                        <div className='p-2 md:p-10'>
                            <div className='w-full'>
                                <Typography variant='h5'>Name</Typography>
                                <Input variant="outlined" label="Name" placeholder="Name" value={agentName} onChange={(e) => setAgentName(e.target.value)}/>
                            </div>
                            
                            <div className='w-full mt-2'>
                                <Typography variant='h5'>Role</Typography>
                                <textarea
                                    className="min-h-[200px] w-full border border-blue-gray-200 rounded-lg p-3"
                                    placeholder="Role description"
                                    value={agentRole}
                                    onChange={(e) => setAgentRole(e.target.value)}
                                />
                            </div>

                            <div className='w-3/6 pt-4'>
                                {/* List errors */}
                                {errors.map((error, index) => (
                                    <Typography color="red" key={index}>{error}</Typography>
                                ))}
                            </div>

                            <div className='pt-2 md:pt-10'>
                                <Button onClick={() => proceed(2)}>Continue</Button>
                            </div>
                        </div>
                    </div>
                )}

                {/* {step === 2 && (
                    <div className='flex flex-col mt-6 md:mt-2'>
                        <div>
                            <Typography variant='h3'>Review your agent</Typography>
                        </div>

                        <hr/>

                        <div className='p-2 md:p-10'>
                            <Typography variant='h4'>{agentName}</Typography>
                            <Typography variant='h5'>{agentRole}</Typography> */}
                            {/* <div>
                                <Checkbox
                                    label={
                                        <div>
                                            <Typography color="blue-gray" className="font-medium">
                                                Map
                                            </Typography>
                                            <Typography variant="small" color="gray" className="font-normal">
                                            📍 Show positions on a map.
                                            </Typography>
                                        </div>
                                    }
                                    onChange={(e) => storeFeature(e, "map")}
                                    checked={agentFeatures.map || false}
                                    containerProps={{
                                        className: "-mt-5",
                                    }}
                                />
                            </div>

                            <div>
                                <Checkbox
                                    label={
                                        <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            Chat
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        💬 Allow chat with AI or with other users.
                                        </Typography>
                                        </div>
                                    }
                                    onChange={(e) => storeFeature(e, "chat")}
                                    checked={agentFeatures.chat || false}
                                    containerProps={{
                                        className: "-mt-5",
                                    }}
                                />
                            </div>

                            <div>
                                <Checkbox
                                    label={
                                        <div>
                                        <Typography color="blue-gray" className="font-medium">
                                            Camera
                                        </Typography>
                                        <Typography variant="small" color="gray" className="font-normal">
                                        📷 Allow camera access
                                        </Typography>
                                        </div>
                                    }
                                    onChange={(e) => storeFeature(e, "camera")}
                                    checked={agentFeatures.camera || false}
                                    containerProps={{
                                        className: "-mt-5",
                                    }}
                                />
                            </div>

                            <div>
                                <Checkbox
                                    label={
                                        <div>
                                            <Typography color="blue-gray" className="font-medium">
                                            Calendar
                                            </Typography>
                                            <Typography variant="small" color="gray" className="font-normal">
                                            📅 Display a calendar
                                            </Typography>
                                        </div>
                                    }
                                    onChange={(e) => storeFeature(e, "calendar")}
                                    checked={agentFeatures.calendar || false}
                                    containerProps={{
                                        className: "-mt-5",
                                    }}
                                />
                            </div> */}

                            {/* <div>
                                <Checkbox
                                    label={
                                        <div>
                                            <Typography color="blue-gray" className="font-medium">
                                                Social
                                            </Typography>
                                            <Typography variant="small" color="gray" className="font-normal">
                                            👥 Allow for friending and presence capabilities
                                            </Typography>
                                        </div>
                                    }
                                    onChange={() => setAgentFeatures({ ...agentFeatures, social: !agentFeatures.social })}
                                    checked={agentFeatures.social}
                                    containerProps={{
                                        className: "-mt-5",
                                    }}
                                />
                            </div> */}

                            {/* <div>
                                <Checkbox
                                    label={
                                        <div>
                                            <Typography color="blue-gray" className="font-medium">
                                                Push Notifications
                                            </Typography>
                                            <Typography variant="small" color="gray" className="font-normal">
                                            🛎️ Allow for push notifications
                                            </Typography>
                                        </div>
                                    }
                                    onChange={(e) => storeFeature(e, "pushNotifications")}
                                    checked={agentFeatures.pushNotifications}
                                    containerProps={{
                                        className: "-mt-5",
                                    }}
                                />
                            </div> */}

                            {/* <div>
                                <Checkbox
                                    label={
                                        <div>
                                            <Typography color="blue-gray" className="font-medium">
                                                Onboarding Questions
                                            </Typography>
                                            <Typography variant="small" color="gray" className="font-normal">
                                                🗒️ Require onboarding questions or create quiz
                                            </Typography>
                                        </div>
                                    }
                                    onChange={(e) => storeFeature(e, "onboardingQuestions")}
                                    checked={agentFeatures.onboardingQuestions}
                                    containerProps={{
                                        className: "-mt-5",
                                    }}
                                />
                            </div> */}

                            {/* <div>
                                <Checkbox
                                    label={
                                        <div>
                                            <Typography color="blue-gray" className="font-medium">
                                                User profile
                                            </Typography>
                                            <Typography variant="small" color="gray" className="font-normal">
                                            👤 Allow for user profile
                                            </Typography>
                                        </div>
                                    }
                                    onChange={(e) => storeFeature(e, "userProfile")}
                                    checked={agentFeatures.userProfile}
                                    containerProps={{
                                        className: "-mt-5",
                                    }}
                                />
                            </div> */}

                            {/* <div>
                                <Checkbox
                                    label={
                                        <div>
                                            <Typography color="blue-gray" className="font-medium">
                                                Custom Feature
                                            </Typography>
                                            <Typography variant="small" color="gray" className="font-normal">
                                            🤖 Add custom feature
                                            </Typography>
                                        </div>
                                    }
                                    onChange={(e) => storeFeature(e, "customFeature")}
                                    checked={agentFeatures.customFeature}
                                    containerProps={{
                                        className: "-mt-5",
                                    }}
                                />
                            </div> */}
                            

                            {/* <div className='pt-2 md:pt-10'>
                                <div className='flex flex-row justify-between'>
                                    <Button onClick={() => proceed(3)}>Continue</Button>
                                    <Button variant='outlined' onClick={() => updateStep(1)}>Go Back</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}

                {step === 2 && (
                    <div className='flex flex-col mt-6 md:mt-2'>
                        <div>
                            <Typography variant='h3'>Summary</Typography>
                        </div>

                        <hr/>

                        <div className='pt-2 md:pt-10'>
                            <Typography color="blue-gray" className="font-bold">
                            Agent Name
                            </Typography>
                            <Typography color="blue-gray" className="font-medium">
                                {agentName}
                            </Typography>

                            <Typography color="blue-gray" className="font-bold">
                                Role
                            </Typography>

                            <Typography color="blue-gray" className="font-medium">
                                {agentRole}
                            </Typography>

                            {/* <Typography color="blue-gray" className="font-bold">
                                Features
                            </Typography> */}

                            {(agentFeaturesDescription).map(feature => (
                                <div key={feature}>
                                    <Typography color="blue-gray" className="font-medium" key={feature}>
                                        {feature}
                                    </Typography>
                                </div>
                            ))}

                            <div className='pt-2 md:pt-10'>
                                <Button onClick={() => proceed(3)}>Save and Continue</Button>
                                <br/>
                                <br/>
                                <Button variant='outlined' onClick={() => updateStep(1)}>Go Back</Button>
                            </div>
                        </div>
                    </div>
                
                )}

                {step === 3 && (
                    <div className='flex flex-col m-6 md:mt-2 w-full'>
                        <div className='w-full text-center p-2 md:p-10'>
                            <Typography variant='h1'>All Done!</Typography>

                            <p>Your agent is ready to use. You can now go to your agent and start using it.</p>

                            <div className='pt-2 pb-2 md:pt-10 md:pb-10'>
                                <Typography variant='h3'>{agentName}</Typography>
                            </div>

                            <hr className='w-1/2 mx-auto' />

                            <div className='pt-2 pb-2 md:pt-10 md:pb-10'>
                                <Typography variant='h3'>{agentRole}</Typography>
                            </div>

                            <div className='pt-2 pb-2 md:pt-10 md:pb-10'>
                                <Button onClick={() => goAgent()}>Go to your agent</Button>
                            </div>
                        </div>
                    </div>
                )}

                </div>
            </div>
        </General>
    );
}